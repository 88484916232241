





























import {ContentElementContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/ContentElementContext';
import Btn from '../../../Component/Misc/Btn/Btn.vue';
import {GoogleTagManagerService} from '../../../Service/GoogleTagManagerService';

export default {
    name: 'UnsolicitedApplication',
    components: {Btn},
    props: {
        context: null as ContentElementContext
    },
    methods: {
        button1Click()
        {
            GoogleTagManagerService.track('unsolicited_application', 'unsolicited_application with cv');
            window.open(this.button1.link, '_blank').focus();
        },
        button2Click()
        {
            GoogleTagManagerService.track('unsolicited_application', 'unsolicited_application without cv');
            window.open(this.button2.link, '_blank').focus();
        }
    },
    computed: {
        button1()
        {
            return this.context.data.get('button1', {});
        },
        button2()
        {
            return this.context.data.get('button2', {});
        }
    }
};
