var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "unsolicitedApplication" },
    [
      _c(
        "btn",
        {
          staticClass: "unsolicitedApplication__button",
          nativeOn: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.button1Click.apply(null, arguments)
            },
          },
        },
        [_vm._v("\n        " + _vm._s(_vm.button1.text) + "\n    ")]
      ),
      _vm._v(" "),
      _c(
        "btn",
        {
          staticClass: "unsolicitedApplication__button",
          attrs: { "is-ghost": true },
          nativeOn: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.button2Click.apply(null, arguments)
            },
          },
        },
        [_vm._v("\n        " + _vm._s(_vm.button2.text) + "\n    ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }