var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "topicsModule" }, [
    _c(
      "div",
      { staticClass: "topicsModule__container" },
      [
        _c("h5", { staticClass: "topicsModule__title" }, [
          _vm._v(_vm._s(_vm.title)),
        ]),
        _vm._v(" "),
        _vm._l(_vm.links, function (link) {
          return _c(
            "div",
            { staticClass: "topicsModule__links" },
            [
              _c("link-wrap", { attrs: { link: link.slug } }, [
                _vm._v(_vm._s(link.title)),
              ]),
            ],
            1
          )
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }