





























import LinkWrap from '../../../../Misc/Link/LinkWrap/LinkWrap.vue';

export default {
    name: 'TopicsModule',
    components: {LinkWrap},
    props: {
        title: String,
        links: Array
    }
};
